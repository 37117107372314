import React from 'react';
import { Link } from 'react-router-dom';
import './Copyright.scss';
import { BiCodeAlt } from 'react-icons/bi'

export default function Copyright() {
  return (
    <footer className='copyright'>
      <span>designed and devleoped <span className='copyright__icon'><BiCodeAlt /></span> by <Link className='copyright__link' to="https://pineconeworkshop.ca">Pinecone Workshop</Link></span>
    </footer>
  )
}
