import React from 'react';
import { Link, useRouteError } from "react-router-dom";
import './NoMatchPage.scss';

export default function NoMatchPage() {

    let error = useRouteError();
    console.error(error);
    return (
        <main className='no-match-page'>
            <section className='no-match-page__info'>
                <h1 className='no-match-page__error'>{error.status}</h1>
                <p>
                    <Link className='no-match-page__link' to="/">Go to the home page</Link>
                </p>
            </section>
        </main>
    )
}
