import React from 'react';
import './Category.scss';
import Item from '../Item/Item';
import Pricing from '../Pricing/Pricing';

export default function Category({ title, subTitle, items, pricing, itemWidth, tables }) {
  return (
    <div className='category'>
      <h3 className='category__title'>{title} {pricing}</h3>
      <div className='category__list'>
        {items && items.map((item, i) => <Item key={i} title={item.title} desc={item.desc} width={itemWidth} />)}
      </div>
      <div className='category__table-list'>
        {tables && tables.map((table, i) => <Pricing key={i}  {...table}/>)}
      </div>
      <h3 className='category__subtitle'>{subTitle}</h3>
    </div>
  )
}