import React from 'react';
import Button from '../Button/Button';
import Header from '../Header/Header';
import parse from 'html-react-parser';
import './Cta.scss';

export default function Cta({id, header, text, button, children}) {

  return (
    <section id={id} className='cta'>
      <div className='cta__container'>
        <Header header={header} />
        <div className='cta__divider'></div>
        <p className='cta__text'>{parse(text)}</p>
        <Button text={button.text} action={button.action} />
        {children}
      </div>
    </section>
  )
}
