import React from 'react';
import './Hero.scss';
import Button from '../Button/Button';

export default function Hero({ logo, buttons }) {
  return (
    <section id="hero" className='hero'>
      <img className='hero__logo' src={logo} alt="welcome to bridge pizza" />
      <div className='hero__ctas'>
        {buttons.map((button, i) => <Button key={i} text={button.text} action={button.action}/>)}
      </div>
    </section>
  )
}
