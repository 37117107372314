import { createBrowserRouter } from "react-router-dom";
import App from '../App';
import { FrontEndRoutes } from "../modules/frontend/FrontEndModule";
import NoMatchPage from "../components/NoMatchPage/NoMatchPage";

export const AppRouter = createBrowserRouter([
    {
        element: <App />,
        children: [
            FrontEndRoutes
        ],
        errorElement: <NoMatchPage />
    },
]);