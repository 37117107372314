import React, { useEffect, useState } from "react";
import Cta from "../../components/Cta/Cta";
import Hero from "../../components/Hero/Hero";
import Nav from "../../components/Nav/Nav";
import logo from "../../assets/img/bridge_logo.png";
import Gallery from "../../components/Gallery/Gallery";
import Menu from "../../components/Menu/Menu";
import Contact from "../../components/Contact/Contact";
import Copyright from "../../components/Copyright/Copyright";
import Background from "../../components/Background/Background";
import Loading from "../../components/Loading/Loading";
import { useFirebaseData } from "../../../../config/firebase-context";
import { isEmptyObject } from "../../../../helpers/utils";
import "./HomePage.scss";

export default function HomePage() {
  const { data, loading } = useFirebaseData();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 200);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading && isEmptyObject(data)) {
    return (
      <>
        <Background />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Background />
      <main id="home" className="home_page">
        {data.nav && <Nav id="nav" {...data.nav} scroll={scroll} />}
        {data.hero && <Hero id="hero" {...data.hero} logo={logo} />}
        {data.about && <Cta id="about" {...data.about} />}
        {data.gallery && <Gallery id="gallery" {...data.gallery} />}
        {data.menu && <Menu id="menu" {...data.menu} />}
        {data.catering && <Cta id="catering" {...data.catering} />}
        {data.footer && <Contact id="contact" {...data.footer} />}
        <Copyright />
      </main>
    </>
  );
}
