import React from 'react';
import Category from './Category/Category';
import Header from '../Header/Header';
import './Menu.scss';

export default function Menu({ id, header, categories }) {
  return (
    <section id={id} className='menu'>
      <Header header={header} />
      <div className='menu__main'>
        {categories && categories.filter(c => c.placement === 'main').map((category, i) => <Category key={i} {...category} />)}
      </div>
      <div className='menu__left'>
        {categories && categories.filter(c => c.placement === 'left').map((category, i) => <Category key={i} {...category} />)}
      </div>
      <div className='menu__right'>
        {categories && categories.filter(c => c.placement === 'right').map((category, i) => <Category key={i} {...category} />)}
      </div>
    </section>
  )
}