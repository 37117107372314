import React from "react";
import Cta from "../Cta/Cta";
import "./Contact.scss";
import { ImFacebook2, ImInstagram } from "react-icons/im";
import { FaTripadvisor, FaGooglePlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Map from "../Map/Map";

export default function Contact({ id, cta, web, socials, hours }) {
  const findIcon = (name) => {
    const icons = [
      {
        name: "facebook",
        icon: <ImFacebook2 />,
      },
      {
        name: "instagram",
        icon: <ImInstagram />,
      },
      {
        name: "tripadvisor",
        icon: <FaTripadvisor />,
      },
      {
        name: "google",
        icon: <FaGooglePlus />,
      },
    ];
    return icons.find((icon) => icon.name === name);
  };

  return (
    <section id={id} className="contact">
      <div className="contact__map">
        <Map />
      </div>
      <div className="contact__info">
        <Cta {...cta}>
          <Link className="contact__web" to="/">
            {web}
          </Link>
          <div className="contact__opening">
            {hours &&
              hours.map((hour, i) => {
                return (
                  <div key={i} className="contact__opening-item">
                    <div className="contact__days">{hour.days}</div>
                    <div className="contact__hours">{hour.times}</div>
                  </div>
                );
              })}
          </div>
          <div className="contact__socials">
            {socials.map((social, i) => (
              <Link className="contact__social" key={i} to={social.link}>
                {findIcon(social.icon).icon}
              </Link>
            ))}
          </div>
        </Cta>
      </div>
    </section>
  );
}
