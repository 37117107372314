import React from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { styles } from './MapStyles';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const lethbridge = {
  lat: 49.669575,
  lng: -112.852032
};

const bridge = {
  lat: 49.66362890000001,
  lng: -112.87917040000002
};

const options = {
  styles: styles,
  zoom: 13,
  scrollwheel: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
}

export default function Map() {

  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })

  const renderMap = () => {
    return <GoogleMap
      mapContainerStyle={containerStyle}
      center={lethbridge}
      options={options}
    >
      <MarkerF icon={require("./MapMarker.png")} position={bridge} />
    </GoogleMap>
  }
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>...loading</div>
}