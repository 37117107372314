import React from 'react';
import parse from 'html-react-parser';
import './Pricing.scss';

export default function Pricing({label, cells}) {
  return (
    <div className='pricing'>
      <div className='pricing__label'>{parse(label)}</div>
      {cells.map((cell, i) => {
        return <div key={i} className='pricing__cell'>
          <div className='pricing__size'>{cell.size}</div>
          <div className='pricing__price'>{cell.price}</div>
        </div>
      })}
    </div>
  )
}
