import React from 'react';
import './Gallery.scss';

export default function Gallery({images}) {
  return (
    <section id="gallery" className='gallery'>
      {images.map((img, i) => <img className='gallery__image' key={i} src={img.image} alt={img.alt} />)}
    </section>
  )
}
