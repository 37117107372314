import React from 'react'
import { Outlet } from 'react-router-dom'
import HomePage from './pages/HomePage/HomePage'
import NoMatchPage from '../../components/NoMatchPage/NoMatchPage'

export default function FrontEndModule() {
    return (
        <Outlet />
    )
}

export const FrontEndRoutes = {
    element: <FrontEndModule />,
    errorElement: <NoMatchPage />,
    children: [
        {
            element: <HomePage />,
            path: "/"
        },
    ]
}