import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import toUpdate from "../modules/frontend/data/homepage.json";

export const batchUpdate = async () => {
  for (const [key, value] of Object.entries(toUpdate)) {
    console.log(key, value);
    await setDoc(doc(db, "site", key), value);
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
