import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

export default function Anchor({link, title, check, main, additional}) {
    const name = check ? additional : main;
    if (link.startsWith("#")) {
        return <HashLink smooth to={link} className={name}>{title}</HashLink>
    } else {
        return <Link to={link} className={name}>{title}</Link>
    }
}
