import React from 'react';
import './Item.scss';

export default function Item({title, desc, width}) {
  return (
    <div className={`item item--${width}`}>
      <h4 className='item__title'>{title}</h4>
      <p className='item__desc'>{desc}</p>
    </div>
  )
}
