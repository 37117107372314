import React, { createContext, useContext, useState, useEffect } from "react";
import { db } from "./firebase";
import { collection, getDocs, query } from "firebase/firestore";

const FirebaseContext = createContext();

export function FirebaseProvider({ children }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const details = {};
      try {
        const q = query(collection(db, "site"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          details[doc.id] = doc.data();
        });
        setData(details);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <FirebaseContext.Provider value={{ data: data, loading: loading }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export function useFirebaseData() {
  return useContext(FirebaseContext);
}
