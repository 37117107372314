import React, { useState, useRef } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import Anchor from "../Anchor/Anchor";
import "./Nav.scss";

export default function Nav({ scroll, links, brand }) {
  const desktop = useMediaQuery(
    { query: "(min-width: 768px)" },
    undefined,
    (matches) => setOpen(matches)
  );
  const [open, setOpen] = useState(desktop);
  const nodeRef = useRef(null);

  return (
    <nav id="nav" className={scroll ? "nav nav--scrolled" : "nav"}>
      <div className="nav__mobile">
        <HashLink to="#home" className="nav__brand">
          {brand}
        </HashLink>
        <button
          className="nav__toggle"
          onClick={() => setOpen((prev) => !prev)}
        >
          <AiOutlineMenu />
        </button>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={open}
        timeout={200}
        classNames="nav__collapse"
        unmountOnExit
      >
        <div className="nav__list-group" ref={nodeRef}>
          <ul className="nav__list">
            {links &&
              links.map((l, i) => {
                return (
                  <Anchor
                    key={i}
                    link={l.link}
                    title={l.title}
                    check={l.orderNow}
                    main="nav__list-link"
                    additional="nav__list-link nav__list-link--order-now"
                  />
                );
              })}
          </ul>
        </div>
      </CSSTransition>
    </nav>
  );
}
